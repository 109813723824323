import React from "react"
import { Provider } from "react-redux"
import store from './createStore';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://42f42fa1ee2c41e7b29fa1b8bcc6cddf@o1340672.ingest.sentry.io/6613453",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {

  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <Provider store={store}>{element}</Provider>
}
