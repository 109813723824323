exports.components = {
  "component---src-components-premium-domain-product-index-js": () => import("./../../../src/components/PremiumDomainProduct/index.js" /* webpackChunkName: "component---src-components-premium-domain-product-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-how-to-start-a-blog-js": () => import("./../../../src/pages/how-to-start-a-blog.js" /* webpackChunkName: "component---src-pages-how-to-start-a-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premium-domains-js": () => import("./../../../src/pages/premium-domains.js" /* webpackChunkName: "component---src-pages-premium-domains-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search/[...].js" /* webpackChunkName: "component---src-pages-search-js" */)
}

