import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

let umami = {
    trackEvent: () => { }
};
if ((typeof window !== 'undefined') && window.umami) umami = window.umami;


export const registrars = [
    { id: 0, name: 'Choose Registrar »' },
    { id: 0, name: '» NameCheap' },
    { id: 1, name: '» Bluehost' },
    { id: 2, name: '» GoDaddy' },
]

export const domainState = createSlice({
    name: 'domain',
    initialState: {
        isLoading: false,
        isCompactMode: false,
        availableDomains: [],
        searchTerm: '',
        registrar: registrars[0]
    },
    reducers: {
        setCompactMode: (state, action) => { state.isCompactMode = action.payload },
        setAvailableDomains: (state, action) => { state.availableDomains = action.payload },
        setIsLoading: (state, action) => { state.isLoading = action.payload },
        setSearchTerm: (state, action) => { state.searchTerm = action.payload },
        setRegistrarId: (state, action) => { state.registrar = registrars[action.payload || 0] },
        resetApp: (state, action) => { state.availableDomains = []; state.searchTerm = ''; },
    }
});

/*
[
  {
    "term": "indy",
    "isPrefix": true,
    "rank": 1315,
    "fullDomain": "indyapp.com"
  },
]
*/


export const clickSearchGo = ({ searchTerm }) => async dispatch => {
    const { GATSBY_API_URL } = process.env;
    if (!GATSBY_API_URL) throw new Error('No ENV');
    umami.trackEvent(`"${searchTerm}"`, 'bigInput');
    if (typeof window !== 'undefined') {
        var permaLink = '/search/' + searchTerm;
        window.history.pushState({ searchTerm }, searchTerm, permaLink);
    }

    dispatch(setIsLoading(true));
    dispatch(setSearchTerm(searchTerm));
    dispatch(setAvailableDomains([]));

    let result;
    try {
        result = await axios.get(`${GATSBY_API_URL}/seo/${searchTerm}`);
        // console.log(result.data);
        dispatch(setAvailableDomains(result.data));
    } catch (err) {
        // [] warnings and alerts
    }
    dispatch(setIsLoading(false));
}


export const {
    setCompactMode,
    setAvailableDomains,
    setIsLoading,
    setSearchTerm,
    setRegistrarId,
    resetApp,
} = domainState.actions;

export const isCompactMode = state => state.domain.isCompactMode;
export const selectAvailableDomains = state => state.domain.availableDomains;
export const selectSearchTerm = state => state.domain.searchTerm;
export const selectIsLoading = state => state.domain.isLoading;
export const selectRegistrar = state => state.domain.registrar;


export default domainState.reducer;